import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import LeadFromHome from "../components/LeadFromHome"

const Page = () => (
  <Layout backgroundColor="#13241F" bannerBgColor="#fb545d" navColor="white">
    <SEO
      title="Thesis | A digital agency"
      keywords={["thesis agency", "portland agency", "marketing agency"]}
      description="Thesis is a full-service digital agency in Portland, OR where strategy, creative, tech, and partner services combine to humanize the digital relationship between brands and people. Using data as the driver, our versatile work has the range to meet people where they are and the relevance to engage people as they are."
    />
    <LeadFromHome />
  </Layout>
)

export default Page
