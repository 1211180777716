import React, { Component } from "react"

import Stanza from "./components/Stanza"

import styles from "./styles.module.less"

export default class LeadFromHome extends Component {
  render() {
    return (
      <div className={styles.LeadFromHome}>
        <Stanza>
          <span>It brought with it more</span>

          <span>Questions than answers, </span>

          <span>Leaving us spinning </span>

          <span>And dizzy and grasping. </span>
        </Stanza>

        <Stanza>
          <span>But not one of us is alone in this fight:</span>

          <span>
            <em>Families, friends, coworkers, clients, strangers.</em>
          </span>

          <span>Everyone—literally everyone— </span>

          <span>Is squinting in the fog,</span>

          <span>Trying to make some sense</span>

          <span>Of this mad season.</span>
        </Stanza>

        <Stanza>
          <span>Our world is feeling it.</span>

          <span>Our country is feeling it. </span>

          <span>Our city is feeling it.</span>

          <span>
            Which means <em>we</em> are feeling it.
          </span>
        </Stanza>

        <Stanza>
          <span>For now, that looks like</span>

          <span>Retreating to our homes—</span>

          <span>And that’s what we’ll do.</span>

          <span>But let us not retreat entirely.</span>

          <span>
            For while <em>stay at home</em> is the mandate,
          </span>

          <span>
            <em>Lead from home</em> is the opportunity:
          </span>
        </Stanza>

        <Stanza>
          <span>When your family</span>

          <span>Is scared, staggering, and stir-crazy,</span>

          <span>Be the steady hand.</span>
        </Stanza>

        <Stanza>
          <span>When your friends</span>

          <span>Are distancing out of a forced new habit,</span>

          <span>Be the glue that binds. </span>
        </Stanza>

        <Stanza>
          <span>When your coworkers </span>

          <span>Are scattered at best, let go at worst,</span>

          <span>Be the first to mobilize. </span>
        </Stanza>

        <Stanza>
          <span> Lift them up.</span>

          <span>Lead the way.</span>

          <span>Pull them through.</span>
        </Stanza>

        <Stanza>
          <span>This may sound a little square</span>

          <span>In a time that’s tilted,</span>

          <span>But it’s the simplest recipe </span>

          <span>To control what we can control. </span>
        </Stanza>

        <Stanza>
          <span>It’s the best way </span>

          <span>To put a little power back in our hands,</span>
        </Stanza>

        <Stanza>
          <span>Make a little sense</span>

          <span>From the otherwise senseless,</span>
        </Stanza>

        <Stanza>
          <span>And persuade the tides </span>

          <span>Once the storm subsides.</span>
        </Stanza>

        <Stanza>
          <span>It would be easy to enter a long sleep.</span>

          <span>But now’s the time to be only proactive.</span>
        </Stanza>

        <Stanza>
          <span>To deliver, not mail it in.</span>

          <span>To make your presence felt,</span>

          <span>Strengthening your heart </span>

          <span>To strengthen our future.</span>
        </Stanza>

        <Stanza>
          <span>Then,</span>

          <span>When this black and bruised version</span>

          <span>Of society begins its mend,</span>

          <span>And some trace of normal</span>

          <span>Begins to take shape,</span>

          <span>You’ll be known not only as the one</span>

          <span>Who survived these times,</span>

          <span>But as the one </span>

          <span>Who rang out loudly from solitude.</span>
        </Stanza>

        <Stanza>
          <span>People,</span>

          <span>In this time of uncertainty,</span>

          <span>Here’s the charge:</span>
        </Stanza>

        <Stanza>
          <span>Sturdy up for your self and family.</span>

          <span>Show up for your team.</span>

          <span>Know there are places</span>

          <span>You can put your energy,</span>

          <span>And with more fire, zeal,</span>

          <span>Vigor, and verve than ever before,</span>
        </Stanza>

        <div className={styles.closing}>
          <Stanza>
            <span>
              <em>Lead. From. Home.</em>
            </span>
          </Stanza>

          <Stanza>
            <span>It will matter.</span>
          </Stanza>
        </div>

        <div style={{ height: "2000px" }}></div>
      </div>
    )
  }
}
